<template>
  <div class="payrecord">
    <top-bar title="账单详情" hasBack></top-bar>
    <!-- 头像 用户名 -->
    <section class="pay-info">
      <div class="pay01">
        <span>余额：{{ dataObj.balance }}</span>
      </div>
      <div class="pay02">缴费户名：{{ dataObj.guestName }}</div>
      <div class="pay02">缴费户号：{{ dataObj.guestNo }}</div>
      <div class="pay02">住址信息：{{ dataObj.location }}</div>
      <div class="pay02">输入金额：</div>
      <div class="pay03">
        ￥<input
          type="text"
          class="pay03-input"
          v-model.trim="amount"
          @input="filteNumber(amount)"
          @click="clickInput"/>
        <div v-if="errorMessage" style="color: red; margin-top: -17px;">{{ errorMessage }}</div>
      </div>
      <div class="pay05">
        <ul>
          <li
              v-for="(item, index) in amountOpts"
              :key="index"
              @click="amount = item"
          >
            <input
                type="radio"
                name="radio"
                class="radio"
                :checked="item === amount"
            /><label for="radio1">{{ item }}元</label>
          </li>
        </ul>
      </div>

      <div class="pay06">
        <van-button
            type="info"
            block
            @click="payByTenant(amount)"
            :disabled="amount.length === 0"
        >
          立即缴费
        </van-button>
      </div>
    </section>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import ajax from '@/api'

export default {
  name: 'pay',
  inject: ['reload'],
  components: {TopBar},
  data() {
    return {
      dataObj: {},
      amount: 50,
      amountOpts: [50, 100, 150, 200, 300],
      bindId: '',
      errorMessage: ''
    }
  },
  watch: {},
  created() {
    const bindId = this.$route.query.bindId
    this.bindId = bindId
    ajax.getAccountDetail({bindId}).then((res) => {
      if (Number(res.code) !== 200) {
        this.$toast.fail(res.msg)
      } else {
        this.dataObj = Object.assign({}, res.data)
      }
    })
  },
  methods: {
    filteNumber(value) {
      if (value.match(/^\d+(\.\d{1,2})?$/)) {
        this.errorMessage = '' // 如果输入符合正则表达式，清除错误信息
      } else {
        this.errorMessage = '输入必须符合格式：（含有两位小数的）正整数' // 如果输入不符合正则表达式，设置错误信息
      }
    },


    // 点击聚焦
    clickInput(e) {
      e.target.focus()
    },
    // 调起微信支付
    onBridgeReady(param) {
      var _this = this
      WeixinJSBridge.invoke('getBrandWCPayRequest', param,
          function (res) {
            if (res.err_msg == 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              _this.$router.push({name: 'PayRecord'})
            }
          })
    },
    //支付片区模式
    pay() {
      ajax.getJSApiPay({
        payChannel: 'wx_pub',
        bindId: this.bindId,
        amount: this.amount * 100,
        totalAmount: this.amount * 100
      }).then(res => {
        if ((Number(res.code) !== 200)) {
          this.$toast.fail(res.msg)
        } else {
          this.onBridgeReady(res.data)
        }
      })
    },
    //支付租户模式
    payByTenant(amount) {
      if (this.dataObj.balance < 0) {
        if (amount < (this.dataObj.balance * -1)) {
          this.errorMessage = '输入金额必须大于欠费金额'
          return
        }
      }
      //mchType: 0:服务商模式;1:普通商户模式
      ajax.getJSApiPay({
        payChannel: 'wx_pub',
        bindId: this.bindId,
        amount: this.amount * 100,
        totalAmount: this.amount * 100,
        //下镇村   wxp4edf49e00
        //威县    wxp01e5c2c13
        // uniqueId: 'wxp01e5c2c13',
        // uniqueId: 'wxp903c10e11',
        mchType: 1
      }).then(res => {
        if ((Number(res.code) !== 200)) {
          this.$toast.fail(res.msg)
        } else {
          this.onBridgeReady(res.data)
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
@import "style";
</style>
